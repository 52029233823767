import React from 'react';
import {
  Layout,
  SEO,
  About,
  Logos,
  Colors,
  LogoPicker,
  Typography,
  Imagery,
  Photography,
  Pattern,
  Textures,
  SupporterGallery,
  WuBanner,
} from '@components';
import { LogoStateProvider } from '@hooks';

const IndexPage = () => (
  <Layout>
    <SEO title="Grassroots Toolkit" />

    <About />

    <LogoStateProvider>
      <LogoPicker />
    </LogoStateProvider>

    <Logos />

    <SupporterGallery />

    <Colors />

    <Typography />

    <Photography />

    <Imagery />

    <Pattern />

    <Textures />

    <WuBanner />
  </Layout>
);

export default IndexPage;
